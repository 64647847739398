import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'react-toastify/dist/ReactToastify.css';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as R from 'ramda';
import { ApolloProvider, ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ToastContainer, toast } from 'react-toastify';

import UserContextProvider from 'src/hooks/useUserContext';

import { icons } from './assets/icons'
import store from './store';

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_HOST}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      Authorization: token ? `JWT ${token}` : "",
    }
  }
});

const errorLink = onError(({ response, graphQLErrors, networkError }) => {
  if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
      );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const responseLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(result => {
    const data = R.propOr({}, 'data', result);
    const errorCode = R.path([0, 'errors', 'nonFieldErrors', 0, 'code'], R.values(data));
    const errorMessage = R.path(['errors', 0, 'message'], result);

    if (errorMessage === 'You do not have permission to perform this action') {
      toast.error(errorMessage);
    }

    if (errorCode === 'unauthenticated') {
      window.location.href = '/#/logout';
    }

    return result
  })
})

const apolloLink = ApolloLink.from([
  authLink,
  responseLink,
  errorLink,
  httpLink,
]);

const client = new ApolloClient({
  link: apolloLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // categories: {
          //   keyArgs: ['id'],
          //   merge(existing, incoming) {
          //     const { edges: newItems } = incoming;
          //     return existing
          //       ? {
          //           ...incoming,
          //           edges: [...existing.edges, ...newItems],
          //         }
          //       : incoming;
          //   },
          // },
        }
      }
    }
  })
});

React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <UserContextProvider>
        <>
          <App/>
          <ToastContainer />
        </>
      </UserContextProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
